import { defineNuxtPlugin } from "#app";
import PouchDB from "pouchdb";
import type { IHorarios, IServicio, Tarjeta, IUserConf } from "@/interfaces";

export default defineNuxtPlugin({
  name: 'db',
  async setup(_nuxtApp) {
    const BASE_URL = 'https://db.mibiotren.cl';

    const Usuario = new PouchDB<{ _id: keyof IUserConf; value: unknown }>(
      'Usuario'
    );
    const ServiciosRemote = new PouchDB<IServicio>(`${BASE_URL}/servicios`);
    const Servicios = new PouchDB<IServicio>('servicios');
    const HorariosRemote = new PouchDB<IHorarios>(`${BASE_URL}/horariosv4`);
    const Horarios = new PouchDB<IHorarios>('horariosv4');
    const Tarjetas = new PouchDB<{ value: Tarjeta }>('Tarjetas');

    const DB_API: typeof _nuxtApp.$db = {
      PouchDB,
      Usuario,
      Servicios,
      ServiciosRemote,
      Horarios,
      HorariosRemote,
      Tarjetas,
    };

    return {
      provide: {
        BASE_URL,
        db: DB_API,
      },
    };
  },
});
