import { useNotifications } from '~/composables/useNotifications';
import { onMessage } from 'firebase/messaging';
import { usePermission } from '@vueuse/core';

export default defineNuxtPlugin(async () => {
  if (import.meta.client && false) {
    // const hasNotifications = usePermission('notifications');
    const { requestPermission, messaging } = useNotifications();

    // Register service worker with correct scope
    if ('serviceWorker' in navigator) {
      try {
        const registration = await navigator.serviceWorker.register(
          '/firebase-messaging-sw.js',
          {
            scope: '/', // No `type: 'module'`
          }
        );
        await requestPermission();

        console.log({ registration, messaging });

        onMessage(messaging, (payload) => {
          console.log('Message received:', payload);
          new Notification(payload.notification?.title || 'New Notification', {
            body: payload.notification?.body,
          });
        });
      } catch (err) {
        console.error('ServiceWorker registration failed:', err);
      }
    }
  }
});
