import { defineStore } from 'pinia';
import deburr from 'lodash/deburr';
import { useNuxtApp } from '#app';

import { PromiseReplicate } from '@/helpers/replicate';
import type { IEstacion, IServicio } from '@/interfaces';
const version = '';

type IServicioDB = IServicio & PouchDB.Core.IdMeta & PouchDB.Core.GetMeta;
type IServicioNew = IServicio & { slug?: string };

export const useServiciosStore = defineStore('servicios', () => {
  const { $db } = useNuxtApp();
  const config = useRuntimeConfig();

  const BASE_URL = config.public.dbUrl || 'https://db.mibiotren.cl';

  let Servicios: null | PouchDB.Database<IServicioDB> = null;
  let ServiciosRemote: null | PouchDB.Database<IServicioDB> = null;

  const servicio = ref('6');
  const servicioId = computed({
    get: () => servicio.value,
    set: (newSrv) => {
      if (servicio.value == newSrv) return;
      servicio.value = String(newSrv);
    },
  });

  const newServicio = computed(() => servicio.value);
  const serviciosArray = ref<IServicioNew[]>([]);
  const initLoad = ref(0);

  const servicios = computed<Map<string, IServicioNew>>(() => {
    return serviciosArray.value?.reduce((init, actual) => {
      if (actual._id && actual.slug) {
        init.set(actual._id, actual);
        init.set(actual.slug, actual);
      }

      return init;
    }, new Map<string, IServicioNew>());
  });

  const servicioActivo = computed<IServicioNew | undefined>(() => {
    return servicios.value.get(String(servicio.value));
  });

  const estaciones = computed<IEstacion[]>(() => {
    const ests = servicioActivo.value?.ests || [];

    return ests.map((est) =>
      Object.assign({}, est, {
        slug: deburr(
          est.nom.toLowerCase().replace(/\s/gm, '-').replace(/\./gm, '')
        ),
      })
    );
  });

  const estacionesId = computed<Map<string, IEstacion>>(() => {
    return estaciones.value?.reduce((init, estacion) => {
      init.set(String(estacion.id), estacion);
      return init;
    }, new Map());
  });

  function startRemote() {
    if (!ServiciosRemote) {
      ServiciosRemote = $db.ServiciosRemote;
    }
    return ServiciosRemote;
  }

  function startLocal() {
    if (!Servicios) {
      Servicios = $db.Servicios;
    }
    return Servicios;
  }

  function initDB() {
    startRemote();
    startLocal();
  }

  function getServicioApi(idServicio: string) {
    return $fetch<IServicioDB>(
      `${BASE_URL}/servicios${version || ''}/${idServicio}`
    );
  }

  function getServicioPouch(idServicio: string) {
    return (
      Servicios?.get(idServicio) ||
      Promise.reject(new Error('Servicios no iniciados'))
    );
  }

  async function getServicio({
    idServicio = '5',
    external = false,
  }: { idServicio?: string; external?: boolean } = {}) {
    let S: IServicioDB | null;

    if (idServicio) {
      servicioId.value = idServicio;
    }

    if (!external && Servicios) {
      S = await getServicioPouch(idServicio).catch((e) => {
        if (e.message === 'missing') {
          return getServicioApi(idServicio);
        }
        return null;
      });

      if (!S) {
        return S;
      }
      serviciosArray.value.push(S);
      return S;
    }

    S = await getServicioApi(idServicio).catch((e) => {
      console.log(e, 'ERROR');
      return null;
    });

    const indexServicio = serviciosArray.value.findIndex(
      (s) => s._id === S?._id
    );
    if (indexServicio < 0) {
      serviciosArray.value.push(Object.assign({}, S, { slug: deburr(S?.srv) }));
    } else {
      serviciosArray.value[indexServicio] = Object.assign({}, S, {
        slug: deburr(S?.srv),
      });
    }

    return S;
  }

  function updateFn(percent: number) {
    initLoad.value = percent;
  }

  async function syncServicios() {
    initDB();

    if (!Servicios || !ServiciosRemote)
      throw new Error('Sin servicios iniciados');

    const { docs_written: docs } = (await PromiseReplicate(
      Servicios,
      ServiciosRemote,
      updateFn
    )) as PouchDB.Replication.ReplicationResult<object>;

    if (docs) {
      await Servicios.compact().catch((e) => console.log(e, 'Compact err'));
      return Promise.resolve(Servicios.allDocs({ include_docs: true }));
    }
    return Promise.reject(Servicios.allDocs({ include_docs: true }));
  }

  async function populateServicios(remote = false) {
    if (!ServiciosRemote && !remote) {
      startRemote();
      if (!ServiciosRemote) throw new Error('Servicios no inicializados');
    }

    let servicios: IServicioNew[] | undefined;

    const parseResult = (
      result: PouchDB.Query.Response<IServicioNew> | undefined
    ): IServicioNew[] | undefined =>
      result?.rows?.map(
        (r: PouchDB.Query.Response<IServicioNew>['rows'][number]) =>
          Object.assign({}, r.doc, { slug: deburr(r.doc?.srv) })
      );

    if (remote) {
      servicios = await $fetch<PouchDB.Query.Response<IServicioNew>>(
        `${BASE_URL}/servicios/_all_docs?include_docs=true`
      ).then(parseResult);
    } else {
      if (!Servicios) throw new Error('Servicios locales no cargados');

      servicios = await Servicios.allDocs({
        include_docs: true,
        attachments: true,
      })
        .catch(() =>
          ServiciosRemote?.allDocs({ include_docs: true, attachments: true })
        )
        .then(parseResult);
    }

    if (!servicios) {
      serviciosArray.value = [];
      return;
    }

    serviciosArray.value = servicios
      .map((ser) => {
        if (ser)
          return Object.assign({}, ser, {
            slug: deburr(ser.srv).toLowerCase(),
          });
      })
      .filter((d) => d)
      .filter((d) => d) as IServicioNew[];

    return serviciosArray.value;
  }

  function cambiarServicio({ servicio: srv = '5' }: { servicio?: string }) {
    if (servicioId.value === srv) {
      return;
    }
    servicioId.value = srv;
  }

  function setServicio({ slug = 'biotren' } = {}) {
    const serv = serviciosArray.value.find(
      (srv) => srv.slug?.toLowerCase() === slug.toLowerCase()
    );

    servicioId.value = serv?._id || '5';

    return serv;
  }

  function existeServicio(slug = 'biotren'): IServicioNew | undefined {
    return servicios.value?.get(slug);
  }

  return {
    // state
    servicio: servicioId,
    newServicio,
    serviciosArray,
    initLoad,

    // actions
    startRemote,
    startLocal,
    initDB,
    getServicioApi,
    getServicioPouch,
    getServicio,
    updateFn,
    syncServicios,
    populateServicios,
    cambiarServicio,
    setServicio,
    existeServicio,

    // getters
    servicios,
    servicioActivo,
    estaciones,
    estacionesId,
  };
});
