import { defineStore } from 'pinia';
import { navigateTo, useNuxtApp, useRuntimeConfig } from 'nuxt/app';
import type { Horario, IEstacion, IHorarios, ITarifa } from '@/interfaces';
import { useServiciosStore } from './servicios';
import { HorarioService } from '~/services/horario.service';

type Database<T extends object> = PouchDB.Database<T>;

interface HorarioAPI {
  salida?: string;
  llegada?: string;
  external?: boolean;
}

interface CambiarRutaParams {
  salida?: string;
  llegada?: string;
  cambiarRuta?: boolean;
}

export const useHorarioStore = defineStore('horarios', () => {
  const config = useRuntimeConfig();
  const app = useNuxtApp();
  const servicios = useServiciosStore();
  const $db = app.$db;

  // Initialize service
  const _horarioService = new HorarioService(
    $db?.Horarios as Database<IHorarios>,
    $db?.HorariosRemote as Database<IHorarios>,
    config.public.dbUrl || 'https://db.mibiotren.cl',
    'v4'
  );

  const salida = ref('16');
  const llegada = ref('35');
  const salidaId = computed<string>({
    get: () => String(salida.value),
    set: (estSalida) => {
      salida.value = String(estSalida);
    },
  });
  const llegadaId = computed<string>({
    get: () => String(llegada.value),
    set: (estLlegada) => (llegada.value = String(estLlegada)),
  });
  const horarios = ref<Horario[]>([]);
  const horariosVuelta = ref<Horario[]>([]);
  const loading = ref(false);
  const initLoad = ref(0);
  const isBuscandoHorario = ref(false);
  const servicioId = computed(() => servicios.servicioActivo?._id || '5');
  const tarifas = ref<ITarifa[]>([]);

  // Initialize database sync
  const initializeSync = () => {
    const syncPromise = _horarioService.syncDatabases(updateFn);
    if (syncPromise?.catch) {
      syncPromise.catch((e: unknown) => {
        if (e instanceof Error) return;
        console.error('Database sync error:', e);
      });
    }
  };

  async function getHorario({
    salida: sal,
    llegada: lle,
    external = false,
  }: HorarioAPI = {}) {
    if (isBuscandoHorario.value) return false;
    isBuscandoHorario.value = true;
    loading.value = true;

    try {
      const horarioSalida = String(sal || salidaId.value);
      const horarioLlegada = String(lle || llegadaId.value);
      const [H, T] = await _horarioService.fetchHorario(
        servicioId.value,
        horarioSalida,
        horarioLlegada,
        external
      );

      horarios.value = H || [];
      tarifas.value = T ? processTarifas(T.tpo) : [];
      return H;
    } catch (error: unknown) {
      console.error('Error fetching horario:', error);
      horarios.value = [];
      tarifas.value = [];
      return [];
    } finally {
      if (sal) salidaId.value = String(sal);
      if (lle) llegadaId.value = String(lle);
      isBuscandoHorario.value = false;
      loading.value = false;
    }
  }

  function processTarifas(tarifasData: ITarifa[]): ITarifa[] {
    return tarifasData.map((t: ITarifa) => ({
      ...t,
      tipo: t.tipo
        .replace(/(biotren|victoria temuco|corto laja)/gi, '')
        .replace(/\s*\/\s*/g, ' ')
        .trim(),
    }));
  }

  function updateFn(percent: number) {
    initLoad.value = percent;
  }

  async function cambiarRuta({
    salida: salidaNew,
    llegada: llegadaNew,
    cambiarRuta = true,
  }: CambiarRutaParams = {}) {
    let sal = String(salidaNew || salidaId.value);
    let lle = String(llegadaNew || llegadaId.value);

    // Handle swap cases first
    if (!llegadaNew && sal === llegadaId.value) {
      lle = salidaId.value;
    } else if (lle === salidaId.value) {
      sal = llegadaId.value;
    }

    // Then check for identical stations
    if (sal === lle) {
      switch (servicioId.value) {
        case '8':
          lle = sal !== '1' ? '1' : '9';
          break;
        case '5':
          lle = sal !== '1' ? '1' : '22';
          break;
        case '6':
        default:
          lle = sal !== '26' ? '26' : '35';
          break;
      }
    }

    salidaId.value = sal;
    llegadaId.value = lle;

    const estSalida = servicios.estacionesId.get(salidaId.value);
    const estLlegada = servicios.estacionesId.get(llegadaId.value);

    console.log({
      servicioId: servicioId.value,
      estSalida,
      estLlegada,
      sal,
      lle,
    });

    if (!estSalida || !estLlegada) {
      console.error('Estaciones no encontradas');
      return Promise.reject(new Error('Estaciones no encontradas'));
    }

    try {
      await getHorario({
        salida: estSalida.id,
        llegada: estLlegada.id,
      });
      const [H] = await _horarioService.fetchHorario(
        servicioId.value,
        estLlegada.id,
        estSalida.id
      );
      if (H) {
        horariosVuelta.value = H;
      }
    } catch (error) {
      console.error('Error fetching horario de vuelta:', error);
      horariosVuelta.value = [];
    } finally {
      if (cambiarRuta) {
        navigateTo({
          params: {
            servicio: servicios.servicioActivo?.slug,
            idsalida: estSalida.slug,
            idllegada: estLlegada.slug,
          },
        });
      }
    }
  }

  const estacionSalida = computed<IEstacion | undefined>(
    () =>
      servicios.estacionesId.get(salidaId.value) ||
      servicios.estaciones.find((e) => String(e.id) === salidaId.value)
  );

  const estacionLlegada = computed<IEstacion | undefined>(
    () =>
      servicios.estacionesId.get(llegadaId.value) ||
      servicios.estaciones.find((e) => String(e.id) === llegadaId.value)
  );

  // Initialize sync after store creation
  initializeSync();

  return {
    salida,
    llegada,
    horarios,
    tarifas,
    loading,
    initLoad,
    horariosVuelta,
    getHorario,
    updateFn,
    cambiarRuta,
    processTarifas,
    estacionSalida,
    estacionLlegada,
    isLoadingHorarios: computed(() => isBuscandoHorario.value),
    horariosVueltaMap: computed<Map<Horario['S'], Horario>>(() =>
      horariosVuelta.value.reduce((init, horario) => {
        init.set(horario.S, horario);
        return init;
      }, new Map())
    ),
  };
});

export type THorariosStore = typeof useHorarioStore;
