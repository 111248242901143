export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"description","content":"Consulta de Horarios, Saldo y Mapas del Biotren de Concepción, Coronel, Talcahuano y Hualqui, todo sin instalar una APP"},{"name":"og:type","content":"website"},{"name":"og:image","content":"https://mibiotren.cl/og-image.jpg"},{"name":"og:title","content":"MiBiotren.cl"},{"name":"og:description","content":"Consulta de Horarios, Saldo y Mapas del Biotren de Concepción, Coronel, Talcahuano y Hualqui, todo sin instalar una APP"},{"name":"twitter:image","content":"https://mibiotren.cl/og-image.jpg"},{"name":"google-site-verification","content":"il1iCDhcsV6MyvZv92S85dBlwNB_2lCPNd0FgAPE2IA"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"canonical","href":"https://mibiotren.cl"},{"rel":"dns-prefetch","href":"https://mibiotren.cl/db"},{"rel":"preconnect","href":"https://mibiotren.cl/db"},{"rel":"dns-prefetch","href":"https://www.google-analytics.com"},{"rel":"preconnect","href":"https://www.google-analytics.com"},{"rel":"dns-prefetch","href":"https://cdnjs.cloudflare.com"},{"rel":"preconnect","href":"https://cdnjs.cloudflare.com"}],"style":[],"script":[{"hid":"adsbygoogle-script","defer":true,"crossorigin":"anonymous","src":"//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8104818500522443"},{"hid":"adsbygoogle","innerHTML":"if (!window.__abg_called){ (window.adsbygoogle = window.adsbygoogle || []); adsbygoogle.pauseAdRequests=0;\n      adsbygoogle.push({\n        google_ad_client: \"ca-pub-8104818500522443\",\n        overlays: {bottom: false},\n        \n      }); window.__abg_called = true;}"}],"noscript":[],"htmlAttrs":{"lang":"es-CL"},"title":"MiBiotren.cl"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false

export const purgeCachedData = true