import payload_plugin_mQVtJobjW7OW1O10VZk96YEjqch3VxfxmGOt_mGcxFg from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_pinia@2.3.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import payload_plugin_ii1TQvGk4HtR5t9eWLCpmbsOPHxR61FQC51yw0qA3_A from "/app/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@13.2.0_firebase-functions@6.3.2_f_ltingbrdwjerweckytjajvtvfi/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_jI9_EMfQ6RWE0LJOs6FT7kA_KsLWY6pihEyVB_0H_IQ from "/app/node_modules/.pnpm/nuxt@3.16.1_@types+node@22.13.14_eslint@9.23.0_rollup@2.79.2_sass@1.86.0_stylelint@16.17.0_typescript@5.8.2_vite@5.4.15/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_t3N9Q0ZqT3BKPumJzMlbeqNvmF5hVUlm66BYqfzJ2OQ from "/app/node_modules/.pnpm/nuxt@3.16.1_@types+node@22.13.14_eslint@9.23.0_rollup@2.79.2_sass@1.86.0_stylelint@16.17.0_typescript@5.8.2_vite@5.4.15/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Ai_YYfVhVviMTue5MEMdfI7GD0_LN4zIuorMCROGA14 from "/app/node_modules/.pnpm/nuxt@3.16.1_@types+node@22.13.14_eslint@9.23.0_rollup@2.79.2_sass@1.86.0_stylelint@16.17.0_typescript@5.8.2_vite@5.4.15/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q from "/app/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_CH_zcw4JaGGKIG2rs4RFh_LX8rzMmfL25wmJAm4LnhE from "/app/node_modules/.pnpm/nuxt@3.16.1_@types+node@22.13.14_eslint@9.23.0_rollup@2.79.2_sass@1.86.0_stylelint@16.17.0_typescript@5.8.2_vite@5.4.15/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_7OGdh1tB4r5oTDQENHyOQvCS36JBaMnWe8HZ_RdxdcQ from "/app/node_modules/.pnpm/nuxt@3.16.1_@types+node@22.13.14_eslint@9.23.0_rollup@2.79.2_sass@1.86.0_stylelint@16.17.0_typescript@5.8.2_vite@5.4.15/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LgX3Sye_GMK8PbPXUCPmD0Oj5nWamaaLgLke9JEEmkE from "/app/node_modules/.pnpm/nuxt@3.16.1_@types+node@22.13.14_eslint@9.23.0_rollup@2.79.2_sass@1.86.0_stylelint@16.17.0_typescript@5.8.2_vite@5.4.15/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_xIAj0hmbrgQ52gGeg7BAwHNh65k_pp35lXFE2v_NuO8 from "/app/node_modules/.pnpm/nuxt@3.16.1_@types+node@22.13.14_eslint@9.23.0_rollup@2.79.2_sass@1.86.0_stylelint@16.17.0_typescript@5.8.2_vite@5.4.15/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Wl9E__6fq48_qYV5ynL7IGZSP9Y3M1g6EfX9ESjUS_c from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_pinia@2.3.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_BuJih4vnefCnBv2mYC92og7au_6tU_M50xUmzqwNULo from "/app/node_modules/.pnpm/nuxt@3.16.1_@types+node@22.13.14_eslint@9.23.0_rollup@2.79.2_sass@1.86.0_stylelint@16.17.0_typescript@5.8.2_vite@5.4.15/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_kWz4xJ_EsQtzZV_M_nNErYb_LUos1pvXC9GlzE1KgCs from "/app/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.10.1_pinia@2.3.1/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import plugin_client_0ZVtAY0Q4ox5dK3_aApNe9qJ_YfIBJin9MVoSlL6BLs from "/app/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@13.2.0_firebase-functions@6.3.2_f_ltingbrdwjerweckytjajvtvfi/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_plugin_3yC4ojquQ_IfYhLPId43C0hGmDvAqA3w4G_VdIrFV5M from "/app/.nuxt/vuefire-plugin.mjs";
import nuxt_plugin_OeEbp0g0XByBDCakBtFuxw6WyAyJkupPzvhVRijm8jo from "/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.8/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg from "/app/.nuxt/pwa-icons-plugin.ts";
import plugin_client_kWmHgLiFrMgDyEyVW1RZ_fi1AdEGwvnKdl6tB_jLhEc from "/app/node_modules/.pnpm/nuxt-gtag@2.1.0/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_client_edbSxve_ISwKTvUFqtVbK44_3Z6bNsEgFUl_SAaH5iQ from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import firebase_client_CoJHhrT3ZeDUrul2mpvKodStAmSgY7mH391tLYlN4AA from "/app/plugins/firebase.client.ts";
import pouchdb_client_Q6mjAtUtS2Vlhi_Pgu14prJQg0l6DNIDkidfIaDmnB4 from "/app/plugins/pouchdb.client.ts";
import pwa_client_sw982UTNjabjXHLIy_WHyOBORVes5VYLuMGcPs_QAZY from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.10.8_@vite-pwa+assets-generator@0.2.6_vite@5.4.15_workbox-build@7.3.0_workbox-window@7.3.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
export default [
  payload_plugin_mQVtJobjW7OW1O10VZk96YEjqch3VxfxmGOt_mGcxFg,
  payload_plugin_ii1TQvGk4HtR5t9eWLCpmbsOPHxR61FQC51yw0qA3_A,
  revive_payload_client_jI9_EMfQ6RWE0LJOs6FT7kA_KsLWY6pihEyVB_0H_IQ,
  unhead_t3N9Q0ZqT3BKPumJzMlbeqNvmF5hVUlm66BYqfzJ2OQ,
  router_Ai_YYfVhVviMTue5MEMdfI7GD0_LN4zIuorMCROGA14,
  _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q,
  payload_client_CH_zcw4JaGGKIG2rs4RFh_LX8rzMmfL25wmJAm4LnhE,
  navigation_repaint_client_7OGdh1tB4r5oTDQENHyOQvCS36JBaMnWe8HZ_RdxdcQ,
  check_outdated_build_client_LgX3Sye_GMK8PbPXUCPmD0Oj5nWamaaLgLke9JEEmkE,
  chunk_reload_client_xIAj0hmbrgQ52gGeg7BAwHNh65k_pp35lXFE2v_NuO8,
  plugin_vue3_Wl9E__6fq48_qYV5ynL7IGZSP9Y3M1g6EfX9ESjUS_c,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_BuJih4vnefCnBv2mYC92og7au_6tU_M50xUmzqwNULo,
  plugin_kWz4xJ_EsQtzZV_M_nNErYb_LUos1pvXC9GlzE1KgCs,
  plugin_client_0ZVtAY0Q4ox5dK3_aApNe9qJ_YfIBJin9MVoSlL6BLs,
  vuefire_plugin_3yC4ojquQ_IfYhLPId43C0hGmDvAqA3w4G_VdIrFV5M,
  nuxt_plugin_OeEbp0g0XByBDCakBtFuxw6WyAyJkupPzvhVRijm8jo,
  pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg,
  plugin_client_kWmHgLiFrMgDyEyVW1RZ_fi1AdEGwvnKdl6tB_jLhEc,
  plugin_client_edbSxve_ISwKTvUFqtVbK44_3Z6bNsEgFUl_SAaH5iQ,
  firebase_client_CoJHhrT3ZeDUrul2mpvKodStAmSgY7mH391tLYlN4AA,
  pouchdb_client_Q6mjAtUtS2Vlhi_Pgu14prJQg0l6DNIDkidfIaDmnB4,
  pwa_client_sw982UTNjabjXHLIy_WHyOBORVes5VYLuMGcPs_QAZY
]