import { $fetch } from 'ofetch';
import type { IHorarios } from '@/interfaces';
import type PouchDB from 'pouchdb';
import { PromiseReplicate } from '@/helpers/replicate';

export class HorarioService {
  public db: PouchDB.Database<IHorarios> | undefined;
  public remoteDB: PouchDB.Database<IHorarios> | undefined;
  public baseUrl: string;
  public version: string;
  public $fetch;

  constructor(
    db: PouchDB.Database<IHorarios> | undefined,
    remoteDB: PouchDB.Database<IHorarios> | undefined,
    baseUrl: string,
    version: string
  ) {
    this.db = db;
    this.remoteDB = remoteDB;
    this.baseUrl = baseUrl;
    this.version = version;
    this.$fetch = $fetch;
  }

  public async getFromPouch(
    idServicio: string,
    idSalida: string,
    idLlegada: string
  ): Promise<[IHorarios['H'], IHorarios['TRF']] | []> {
    const doc = await this.db?.get<IHorarios>(
      `${idServicio}>${idSalida}>${idLlegada}`
    );

    return doc ? [doc?.H, doc?.TRF] : [];
  }

  public async getFromAPI(
    idServicio: string,
    idSalida: string,
    idLlegada: string
  ): Promise<[IHorarios['H'], IHorarios['TRF']]> {
    try {
      const response = await this.$fetch<IHorarios>(
        `${this.baseUrl}/horarios${this.version}/${idServicio}>${idSalida}>${idLlegada}`
      );
      return [response.H, response.TRF];
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async fetchHorario(
    idServicio: string,
    idSalida: string,
    idLlegada: string,
    external = false
  ): Promise<[IHorarios['H'], IHorarios['TRF']] | []> {
    if (external) {
      return this.getFromAPI(idServicio, idSalida, idLlegada);
    } else {
      return this.getFromPouch(idServicio, idSalida, idLlegada).catch(
        async (error) => {
          // TODO: Type this correctly
          if (error.status === 404 || error.message === 'missing') {
            return this.getFromAPI(idServicio, idSalida, idLlegada);
          }
          throw error;
        }
      );
    }
  }

  async syncDatabases(updateFn: (percent: number) => void) {
    try {
      await this.compact();
      if (!this.db || !this.remoteDB)
        throw new Error('Databases not initialized');
      const docs = await PromiseReplicate(
        this.db,
        this.remoteDB,
        updateFn
      ).then((d) => d.docs_written);

      if (docs) {
        await this.compact();
        return this.getAllHorarios();
      }
      return this.getAllHorarios();
    } catch (error) {
      // TODO: Type this correctly
      console.error('Database sync error:', error);
      return Promise.reject(error);
    }
  }

  async getAllHorarios() {
    try {
      await this.compact();
      return this.db?.allDocs({ include_docs: true });
    } catch (error) {
      // TODO: Type this correctly
      return Promise.reject(error);
    }
  }

  public async compact() {
    return this.db?.compact().catch((e) => console.log(e, 'Compact err'));
  }
}
